import { render, staticRenderFns } from "./ScrollArea.vue?vue&type=template&id=10ee9d2c&scoped=true"
import script from "./ScrollArea.vue?vue&type=script&lang=ts"
export * from "./ScrollArea.vue?vue&type=script&lang=ts"
import style0 from "./ScrollArea.vue?vue&type=style&index=0&id=10ee9d2c&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10ee9d2c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BgButton: require('/artifacts/BGFrontend/components/Ui/BgButton.vue').default})
