
export default {
    methods: {                  
        start() {
            this.$store.commit('SET_LOADING', true)
        },
        finish() {
            this.$store.commit('SET_LOADING', false)
        },
    },
}
