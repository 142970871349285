import { render, staticRenderFns } from "./AbstractPlayerStats.vue?vue&type=template&id=f123e8b8&scoped=true&lang=pug"
import script from "./AbstractPlayerStats.vue?vue&type=script&lang=ts"
export * from "./AbstractPlayerStats.vue?vue&type=script&lang=ts"
import style0 from "./AbstractPlayerStats.vue?vue&type=style&index=0&id=f123e8b8&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f123e8b8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Award: require('/artifacts/BGFrontend/components/Ui/Award.vue').default,BgButton: require('/artifacts/BGFrontend/components/Ui/BgButton.vue').default,MoneyLabel: require('/artifacts/BGFrontend/components/Ui/MoneyLabel.vue').default})
