
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

export enum EAwardsIds {
    CREDIT_1 = "credits_1",
    CREDIT_2 = "credits_2",
    RATING_1 = "ratings_1" ,
    RATING_2 = "ratings_2",
    TOURNAMENTS_1 = "tournaments_1",
    TOURNAMENTS_2 = "tournaments_2"
}

export enum EAwardsTitles {
    CREDIT_1 = 'Awards.credits_1',
    CREDIT_2 = 'Awards.credits_2',
    RATING_1 = 'Awards.ratings_1',
    RATING_2 = 'Awards.ratings_2',
    TOURNAMENTS_1 = 'Awards.tournaments_1',
    TOURNAMENTS_2 = 'Awards.tournaments_2'
}

export const EAwardsList = [
    {caption: EAwardsTitles.CREDIT_1, id: EAwardsIds.CREDIT_1},
    {caption: EAwardsTitles.CREDIT_2, id: EAwardsIds.CREDIT_2},
    {caption: EAwardsTitles.RATING_1, id: EAwardsIds.RATING_1},
    {caption: EAwardsTitles.RATING_2, id: EAwardsIds.RATING_2},
    {caption: EAwardsTitles.TOURNAMENTS_1, id: EAwardsIds.TOURNAMENTS_1},
    {caption: EAwardsTitles.TOURNAMENTS_2, id: EAwardsIds.TOURNAMENTS_2}
];

@Component({})
export default class Award extends Vue {
    @Prop(String) id !: String

    get bgStyle(){
        return  { backgroundImage: 'url(' + require('@/assets/images/ui/awards/'+this.id.toString()+'.png') + ')' }
    }

    get title() {
        switch ( this.id ) {
            case EAwardsIds.CREDIT_1: return this.$t(EAwardsTitles.CREDIT_1)
            case EAwardsIds.CREDIT_2: return this.$t(EAwardsTitles.CREDIT_2)
            case EAwardsIds.RATING_1: return this.$t(EAwardsTitles.RATING_1)
            case EAwardsIds.RATING_2: return this.$t(EAwardsTitles.RATING_2)
            case EAwardsIds.TOURNAMENTS_1: return this.$t(EAwardsTitles.TOURNAMENTS_1)
            case EAwardsIds.TOURNAMENTS_2: return this.$t(EAwardsTitles.TOURNAMENTS_2)
            default: return this.id
        }
    }
}
