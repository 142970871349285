


import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { isMobile } from 'mobile-device-detect';

@Component({        
})
export default class ScrollArea extends Vue {

    @Prop ({ type:Boolean, default: false }) readonly noTitle!: boolean

    scrolledUp:boolean = true;
    scrolledBottom:boolean = false
    isMobile:boolean = isMobile
    timerButtons:NodeJS.Timeout | null = null;
            
    mounted() {
        this.updateScrollStatus();

        if(!this.timerButtons) {
            // TODO findout event when changed scrolled size
            this.timerButtons = setInterval( ()=>{
                this.updateScrollStatus();
            },500);
        }        
    }

    destroyed() {
        if(this.timerButtons) {
            clearInterval(this.timerButtons);
            this.timerButtons = null;
        }
    }


    onResize() {   
        //TODO  
        this.updateScrollStatus();
    }
    onScroll() {
        this.updateScrollStatus();                                                                                          
    }

    updateScrollStatus() {        
        let container = <Element | undefined >this.$refs.scrollArea;
        if (!container)
            return
         
        const DELTA = 10;
        let bottomHeight = container.scrollHeight - container.clientHeight;
        this.scrolledBottom = container.scrollTop > (bottomHeight - DELTA);
        // console.log("container.scrollTop", container.scrollTop)
        // console.log("bottomHeight", bottomHeight)
        this.scrolledUp = container.scrollTop < DELTA    
    }


    scrollUp() {
        let component = <Element>this.$refs.scrollArea;
        if(!component)
            return;
        component.scrollTop = 0
    }

    scrollDown() {
        let component = <Element>this.$refs.scrollArea;
        if(!component)
            return;
                    
        component.scrollTop = component.scrollHeight
    }
}
