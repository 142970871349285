

import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IPlayerStatistics } from '~/modules/model/Types/IPlayerStatistics'
//import { Award } from '~/components/Ui/Award.vue'
import { EDialogEvents } from '../Dialogs.vue'
import { isMobile } from 'mobile-device-detect';
import { EAwardsIds } from './Award.vue';
import { mapGetters, mapState } from 'vuex'
import {ECurrencyAbbreviation} from '~/modules/model/Types/Currency'

@Component({})
export default class WindowStatisticGeneral extends Vue {   
    isMobile:boolean = isMobile  
    @Prop(Object) statistics !: IPlayerStatistics

    on_info() {
        this.$root.$emit(EDialogEvents.SHOW_AWARDS_INFO, this.awrds); 
    }

    get awrds() { 
        const awards: string[] = [];
        if (this.tops_tournaments)
            awards.push(this.tops_tournaments>3?EAwardsIds.TOURNAMENTS_2:EAwardsIds.TOURNAMENTS_1);
        if (this.tops_ratings)
            awards.push(this.tops_ratings>3?EAwardsIds.RATING_2:EAwardsIds.RATING_1);
        if (this.tops_credits)
            awards.push(this.tops_credits>3?EAwardsIds.CREDIT_2:EAwardsIds.CREDIT_1);
        return awards;
    }

    get typeCr () {return ECurrencyAbbreviation.CREDIT;}
    get typeGLD () {return ECurrencyAbbreviation.GOLD;}

    get isTops() { return (this.tops_credits || this.tops_ratings || this.tops_tournaments);}

    get tops_credits() { return (this.statistics && this.statistics.tops.credits_position)? this.statistics.tops.credits_position : ''}
    get tops_ratings() { return (this.statistics && this.statistics.tops.ratings_position)? this.statistics.tops.ratings_position : ''}
    get tops_tournaments() { return (this.statistics && this.statistics.tops.tournaments_position)? this.statistics.tops.tournaments_position : ''}

    get credits_value() { return ''+(this.statistics ? this.statistics.credits.value : '')}
    get credits_won() { return ''+(this.statistics ? this.statistics.credits.win : '') }
    get credits_lost() { return ''+(this.statistics ? this.statistics.credits.loose : '') }

    get gold_value() { return ''+(this.statistics ? this.statistics.golds.value : '')}
    get gold_won() { return ''+(this.statistics ? this.statistics.golds.win : '') }
    get gold_lost() { return ''+(this.statistics ? this.statistics.golds.loose : '') }
    
    get dice_rolls() { return this.statistics ? this.statistics.dice.count : '' }
    get dice_drops() { return this.statistics ? this.statistics.dice.sum : '' }
    get duplicates_dropped() { return this.statistics ? this.statistics.dice.double_count : '' }

    get dropped_value_1() { return this.statistics ? this.statistics.dice.double_counts[ 0 ] : '' }
    get dropped_value_2() { return this.statistics ? this.statistics.dice.double_counts[ 1 ] : '' }
    get dropped_value_3() { return this.statistics ? this.statistics.dice.double_counts[ 2 ] : '' }
    get dropped_value_4() { return this.statistics ? this.statistics.dice.double_counts[ 3 ] : '' }
    get dropped_value_5() { return this.statistics ? this.statistics.dice.double_counts[ 4 ] : '' }
    get dropped_value_6() { return this.statistics ? this.statistics.dice.double_counts[ 5 ] : '' }

    get parity_games() { return this.statistics ? this.statistics.game.parity : '' }
    get games_won() { return this.statistics ? this.statistics.game.win : '' }
    get games_lost() { return this.statistics ? this.statistics.game.loose : '' }

    get rating() { return this.statistics ? (this.statistics.rating.value ? this.statistics.rating.value : '0') : '' }

    get tournaments_won() { return this.statistics ? this.statistics.tournament.win : '' }
    get tournaments_lost() { return this.statistics ? this.statistics.tournament.loose : '' }

    get isStarz() {
        return process.env.STARZ_GAME === "1"
    }
}
